<script>
import { formatDate } from '/~/utils/format/date'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseProgressBar from '/~/components/base/progress-bar/base-progress-bar.vue'
import modal from '/~/core/mdl'
import PostMedia from '/~rec/components/post/post-media.container.vue'
import PostQuizOption from './post-quiz-option.vue'
import PostLinkPreviews from '/~rec/components/post/post-link-previews.container.vue'
import RenderMessage from '/~rec/components/render/render-message.vue'

export default {
  name: 'rec-post-quiz',
  components: {
    PostQuizOption,
    PostMedia,
    BaseProgressBar,
    BaseButton,
    BaseIcon,
    PostLinkPreviews,
    RenderMessage,
    BaseDate,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      pluralize,
    }
  },
  data() {
    return {
      selectedOption: null,
      activeQuestionIndex: 1,
      process: false,
    }
  },
  computed: {
    isQuizInProgress() {
      return this.post.hasSubmission && this.post.submission.isStatusProgress
    },
    isQuizCompleted() {
      return this.post.hasSubmission && this.post.submission.isStatusCompleted
    },
    icon() {
      if (this.isQuizCompleted) {
        return this.post.submission.isPassed
          ? 'rec/round/thumb-up'
          : 'rec/round/thumb-down'
      }

      return 'rec/round/quiz'
    },
    title() {
      if (this.isQuizCompleted) {
        return this.post.submission.isPassed
          ? 'Congratulations, you have passed this Quiz!'
          : 'Unfortunately, you have failed this Quiz!'
      }

      return 'It’s Quiz time, showcase your knowledge!'
    },
    score() {
      return `${
        this.post.hasSubmission ? this.post.submission.totalCorrect : 0
      }/${this.post.countOfQuestions}`
    },
    questions() {
      if (this.isQuizInProgress) {
        return `Question ${this.post.submission.totalAnswered}/${this.post.countOfQuestions}`
      }
      return `${this.pluralize(
        this.post.countOfQuestions,
        `${this.post.countOfQuestions} question`
      )} Quiz`
    },
    activeQuestion() {
      return this.post.questions[this.activeQuestionIndex - 1]
    },
    isLastQuestion() {
      return this.activeQuestionIndex >= this.post.countOfQuestions
    },
  },
  created() {
    this.activeQuestionIndex = this.isQuizInProgress
      ? this.post.submission.totalAnswered
      : 1
  },
  methods: {
    formatDate,
    onNextClick() {
      if (this.isLastQuestion) {
        this.process = false
      } else {
        this.activeQuestionIndex++
      }

      this.selectedOption = null
    },
    onLeaveClick() {
      modal.show('rec-quiz-leave', {
        on: {
          left: () => {
            this.process = false
          },
        },
      })
    },
    onSelectOption(questionId, optionId) {
      if (this.selectedOption) return

      this.selectedOption = optionId
      this.post.answer(questionId, optionId)
    },
    onProcess() {
      if (this.isQuizInProgress) {
        const answer = this.post.submission.answers.find(
          (a) => a.questionId === this.activeQuestion.id
        )

        if (answer) this.selectedOption = answer.optionId
      }

      this.process = true
    },
    onViewResults() {
      if (!this.post.totalAnswered) return

      modal.show('rec-quiz-submissions', {
        props: {
          post: this.post,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="!process" key="content" class="bg-white">
        <div class="mx-6 flex flex-col">
          <b class="block text-xl leading-6">
            {{ title }}
          </b>

          <b v-if="isQuizCompleted" class="mt-2.5 block text-xl leading-6">
            Score {{ score }}
          </b>

          <div
            v-if="isQuizCompleted && post.hasEarnConfigs"
            class="mb-[5px] mt-6 flex items-center text-amber-400"
          >
            <base-icon svg="rec/token" class="mr-2.5" size="md" />
            <b class="text-xl leading-6">
              {{ post.submission.totalEarn }}
              <span class="capitalize">
                {{ post.currency }}
              </span>
              earned
            </b>
          </div>

          <b
            v-if="post.endsAt"
            :class="[
              'mt-2.5 block text-sm',
              post.isExpired ? 'text-red-700' : 'text-eonx-neutral-600',
            ]"
          >
            <span>{{ post.isExpired ? 'Ended' : 'Ends on' }}</span>
            <base-date
              :date="formatDate('daymonthyear', post.endsAt)"
              type="date-spr"
            />
          </b>

          <b
            v-if="post.isMeAuthor || !isQuizCompleted"
            class="mt-2.5 block text-sm text-eonx-neutral-600"
          >
            <template v-if="post.isMeAuthor">
              {{ post.totalAnswered }}
              {{ pluralize(post.totalAnswered, 'user') }} answered your Quiz
            </template>
            <template v-else-if="!isQuizCompleted">
              {{ questions }}
            </template>
          </b>

          <div class="mt-[15px]">
            <render-message
              class="leading-normal"
              :content="post.content"
              :mentions="post.mentions"
            />
          </div>
        </div>

        <post-media
          v-if="post.attachments.hasAttachments"
          class="mt-5"
          :post="post"
        />
        <post-link-previews :post="post" />

        <div
          v-if="post.isMeAuthor || !isQuizCompleted"
          class="mx-[30px] mt-5 flex flex-col items-center"
        >
          <base-button
            v-if="post.isMeAuthor"
            :disabled="!post.totalAnswered"
            class="min-w-56 text-sm"
            @click="onViewResults"
          >
            View results
          </base-button>
          <template v-else-if="!isQuizCompleted">
            <base-button
              class="w-72 max-w-full"
              :disabled="post.isExpired"
              @click="onProcess"
            >
              <span class="text-sm">
                {{
                  isQuizInProgress
                    ? 'Resume Quiz'
                    : 'I’m ready to take this Quiz'
                }}
              </span>
            </base-button>

            <div class="mt-[15px] flex items-center text-orange-400">
              <base-icon svg="rec/exclamation" :size="20" />
              <b class="ml-[5px] block font-bold leading-5">
                You can only take this Quiz once
              </b>
            </div>
          </template>
        </div>
      </div>

      <div v-else key="process" class="overflow-hidden bg-white">
        <div class="rounded-b-xl bg-white">
          <div class="px-6 py-[15px]">
            <base-button class="block" look="link" @click="onLeaveClick">
              <div class="flex items-center leading-4">
                <base-icon
                  svg="rec/chevron-left"
                  size="xxs"
                  class="mr-[15px]"
                />
                Leave Quiz
              </div>
            </base-button>

            <div class="my-5">
              <base-progress-bar
                :progress="activeQuestionIndex"
                :total="post.countOfQuestions"
              />
            </div>

            <div class="flex items-center justify-between font-bold leading-5">
              <span class="text-eonx-neutral-600">
                Question {{ activeQuestionIndex }}
              </span>
              <span>Score {{ score }}</span>
            </div>

            <span class="mt-2.5 block break-words">
              {{ activeQuestion.content }}:
            </span>
          </div>
        </div>

        <div class="pb-[15px] pt-2.5 sm:pt-6">
          <div class="flex flex-nowrap">
            <template v-for="(question, id) in post.questions">
              <transition :key="id" name="slide-right">
                <ul
                  v-if="activeQuestionIndex === id + 1"
                  class="w-full shrink-0 px-6"
                >
                  <post-quiz-option
                    v-for="(option, idx) in question.options"
                    :key="`o-${idx}`"
                    :selected-option="selectedOption"
                    :option="option"
                    :idx="idx"
                    @click="onSelectOption(question.id, option.id)"
                  />
                </ul>
              </transition>
            </template>
          </div>

          <div class="h-5 px-[30px]">
            <transition name="slide-y">
              <div
                v-if="selectedOption"
                class="flex h-full items-center justify-end"
              >
                <base-button look="link" @click="onNextClick">
                  <div class="flex items-center leading-4">
                    {{ isLastQuestion ? 'End Quiz' : 'Next Question' }}
                    <base-icon
                      svg="rec/chevron-right"
                      size="xxs"
                      class="ml-[15px]"
                    />
                  </div>
                </base-button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
