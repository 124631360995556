<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import ActionsPopover from './actions-popover.vue'
import { useFeeds } from '/~rec/composables/feeds'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-actions',
  components: {
    BaseButton,
    ActionsPopover,
  },
  props: {
    isCommentsVisible: {
      type: Boolean,
      default: false,
    },
    feed: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { feeds } = useFeeds()
    const { features } = useRecProfile()

    return { feeds, features }
  },
  data() {
    return {
      saving: false,
    }
  },
  methods: {
    onLike() {
      if (!this.post.isLikedByMe) {
        return this.post.like()
      }
    },
    async onSave() {
      const { post } = this

      this.saving = true
      if (post.isSavedByMe) {
        await post.unsaveFromMe()
        if (this.feed.entity === 'saved-posts') {
          this.feed.removeHit(post)

          // Manually unsave post in stored news feed for consistency after switching from saved -> news feeds.
          const hit = this.feeds.feed.hits.find((h) => h.id === post.id)

          if (hit) {
            hit.unsaveFromMe(false)
          }
        }
      } else {
        await this.post.saveForMe()
      }
      this.saving = false
    },
    toggleComments() {
      this.$emit('update:isCommentsVisible', !this.isCommentsVisible)
    },
  },
}
</script>

<template>
  <div class="flex items-center space-x-[15px] pt-[5px]">
    <actions-popover
      :is-visible="post.isActionsAllow('like')"
      :entity="post.likes"
      :count="post.totalLikes"
      title="Liked"
    >
      <template #button-action>
        <base-button
          class="text-primary"
          :class="{
            'pointer-events-none': post.isLikedByMe,
          }"
          :icon="post.isLikedByMe ? 'rec/heart-filled' : 'rec/heart'"
          :size="18"
          title="Like this"
          @click="onLike()"
        />
      </template>
    </actions-popover>

    <actions-popover
      :is-visible="post.isActionsAllow('comment') && features.comments"
      :entity="post.commentators"
      :count="post.totalComments"
      title="Comments"
    >
      <template #button-action>
        <base-button
          :icon="isCommentsVisible ? 'rec/comment-filled' : 'rec/comment'"
          class="text-primary"
          :size="18"
          title="View Comments"
          @click="toggleComments"
        />
      </template>
    </actions-popover>

    <actions-popover
      :is-visible="post.isActionsAllow('save')"
      :entity="post.savers"
      :count="post.totalSaved"
      title="Saved"
    >
      <template #button-action>
        <base-button
          class="text-primary"
          :icon="post.isSavedByMe ? 'rec/bookmark-filled' : 'rec/bookmark'"
          :size="18"
          title="Save this"
          :disabled="saving"
          @click="onSave()"
        />
      </template>
    </actions-popover>
  </div>
</template>
