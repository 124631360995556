<script>
import RenderRelatedAvatars from '/~rec/components/render/render-related-avatars.vue'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-hi5',
  components: {
    RenderRelatedAvatars,
    RecognitionHeader,
    BaseLink,
    BaseButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { myRecId } = useRecProfile()

    return { myRecId }
  },
  computed: {
    from() {
      const { sender } = this.post

      return (
        sender && {
          src: sender.avatarUrl,
          abbr: sender.initials,
        }
      )
    },
    to() {
      return { src: this.post.reason.image }
    },
    entity() {
      return { src: this.cdn('/images/recroom/hi5-round.svg').url() }
    },
    isMeRecipient() {
      return this.post.recipient.id === this.myRecId
    },
  },
}
</script>

<template>
  <div>
    <recognition-header icon="rec/round/hi5" />

    <div class="px-6 pb-2.5 sm:px-[30px]">
      <h2 class="mb-[15px] text-center leading-tight">
        {{ isMeRecipient ? 'You' : post.recipient.fullName }} received a Hi-5
      </h2>

      <render-related-avatars
        class="my-[15px]"
        :from="from"
        :to="to"
        :entity="entity"
      />

      <div class="text-center text-sm font-bold text-eonx-neutral-600">
        <span class="text-black">
          {{ post.sender.fullName }}
        </span>

        sent
        <template v-if="isMeRecipient">you</template>
        <span v-else class="text-black">
          {{ post.recipient.fullName }}
        </span>
        a Hi-5 for

        <span class="text-black">
          {{ post.reason.content }}
        </span>
      </div>

      <div class="my-6 text-center">
        {{ post.message }}
      </div>

      <div class="flex justify-center">
        <base-link :to="{ name: 'rec-hi5' }">
          <base-button class="min-w-56">See Hi-5</base-button>
        </base-link>
      </div>
    </div>
  </div>
</template>
