<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useLocalization } from '/~/composables/localization'
export default {
  name: 'rec-post-award',
  components: {
    BaseButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { features } = useRecProfile()
    const { translate } = useLocalization()

    return { features, translate }
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'rec-award',
        params: {
          awardId: this.post.memberAwardId,
          awardStatus: 'finished',
          winnerId: this.post.winnerId,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="flex flex-col px-[15px] pb-2.5 md:px-6">
      <span class="break-words text-eonx-neutral-800">
        {{ post.award.description }}
      </span>

      <base-button
        v-if="features.awards"
        class="mx-auto mt-6 w-full max-w-80"
        @click="onClick"
      >
        <div class="truncate">
          See Award information {{ translate('common.and') }} leaderboard
        </div>
      </base-button>
    </div>
  </div>
</template>
