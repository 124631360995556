<script>
import modal from '/~/core/mdl'
import PostMedia from '/~rec/components/post/post-media.container.vue'
import BaseBadge from '/~/components/base/badge/base-badge.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import RenderMessage from '/~rec/components/render/render-message.vue'
import PostLinkPreviews from '/~rec/components/post/post-link-previews.container.vue'

export default {
  name: 'rec-post-poll',
  components: {
    PostMedia,
    BaseButton,
    BaseIcon,
    BaseBadge,
    BaseDate,
    BaseRadio,
    RenderMessage,
    PostLinkPreviews,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedId: null,
      processing: false,
    }
  },
  methods: {
    async onVote() {
      try {
        this.processing = true
        await this.post.vote(this.selectedId)
        this.selectedId = null
      } catch (error) {
        console.error('rec-room', error)
      } finally {
        this.processing = false
      }
    },
    onVotesCountClick(option) {
      modal.show('rec-poll-voter-list', {
        props: { option },
      })
    },
  },
}
</script>

<template>
  <div class="bg-white">
    <div class="rounded-b-xl bg-white">
      <div class="px-6 pb-[15px]">
        <b class="block text-xl leading-6">
          Share your opinion, it’s Poll time!
        </b>
        <b
          v-if="post.expiresAt"
          class="mt-2.5 block text-sm"
          :class="{
            'text-red-700': post.isExpired,
            'text-eonx-neutral-600': !post.isExpired,
          }"
        >
          <span>{{ post.isExpired ? 'Ended' : 'Ends on' }}</span>
          <base-date :date="post.expiresAt" type="date-spr" />
        </b>
        <div class="mt-[15px]">
          <render-message
            class="leading-normal"
            :content="post.content"
            :mentions="post.mentions"
          />
        </div>

        <post-media :post="post" />
      </div>
    </div>

    <div class="bg-gray-50 px-6 py-[15px]">
      <div
        v-for="option in post.options"
        :key="option.raw.id"
        class="relative z-10 my-[15px] flex min-h-16 items-center overflow-hidden rounded-xl bg-white"
      >
        <div
          class="flex w-full items-center justify-between"
          :class="{
            'py-2.5 pl-[30px] pr-6 sm:px-[30px]':
              post.isVotedByMe || post.isMeAuthor,
          }"
        >
          <div class="flex w-full flex-col items-start">
            <template v-if="post.isVotedByMe || post.isMeAuthor">
              <b class="grid grid-cols-1 break-words">
                {{ option.content }}
              </b>

              <base-button
                look="link"
                size="md"
                :disabled="!option.countOfAnswers || post.isPrivate"
                @click="onVotesCountClick(option)"
              >
                <span class="text-primary">
                  <span
                    v-if="option.countOfAnswers === 0"
                    class="text-eonx-neutral-600"
                  >
                    No votes
                  </span>
                  <span v-else-if="option.countOfAnswers === 1">1 vote</span>
                  <span v-else>{{ option.countOfAnswers }} votes</span>
                </span>
              </base-button>
            </template>
            <base-radio
              v-else
              v-model="selectedId"
              :value="option.id"
              :disabled="post.isExpired"
              name="option"
              class="w-full px-6 py-2.5 sm:px-[30px]"
            >
              <b
                class="grid grid-cols-1 whitespace-normal break-words"
                :class="{
                  'text-eonx-neutral-600': post.isExpired,
                }"
              >
                {{ option.content }}
              </b>
            </base-radio>
          </div>

          <base-badge
            v-if="
              (post.isVotedByMe || post.isMeAuthor) && option.countOfAnswers
            "
            class="ml-5 min-w-11"
            rounded
          >
            {{ Math.round((option.countOfAnswers / post.totalAnswers) * 100) }}%
          </base-badge>
        </div>

        <div
          class="absolute h-full border-l-8 border-transparent transition-colors duration-200"
          :class="{
            'border-primary': option.isVotedByMe || selectedId === option.id,
          }"
        />
      </div>

      <div class="min-h-[30px]">
        <transition name="slide-y">
          <div v-if="!post.isExpired && selectedId" class="flex justify-end">
            <base-button
              :loading="processing"
              :disabled="post.isExpired"
              look="link"
              @click="onVote()"
            >
              <span>Vote</span>
              <base-icon svg="rec/paper-plane" class="ml-2.5" size="lg" />
            </base-button>
          </div>
        </transition>
      </div>
    </div>
    <post-link-previews :post="post" />
  </div>
</template>
