<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import CommentInput from './comment-input.vue'
import MediaGallery from '/~rec/components/media/media-gallery.vue'
import MediaDownloadable from '/~rec/components/media/media-downloadable.vue'
import MediaAudio from '/~rec/components/media/media-audio.vue'
import processTextForUrls from '/~/utils/process-text-for-urls'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { fromIdToMention } from '/~rec/utils/process-text-for-mentions'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-comment',
  components: {
    CommentInput,
    MediaAudio,
    MediaGallery,
    MediaDownloadable,
    BaseButton,
    BaseIcon,
    BaseAvatar,
    BaseDate,
    BaseMenu,
  },
  inject: {
    processor: {
      default: () => ({}),
    },
    post: {
      default: () => ({}),
    },
    sources: {
      default: () => ({ source: null }),
    },
  },
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
    reply: {
      type: Boolean,
      default: false,
    },
    showReply: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { profile, features, isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return {
      me: profile.me,
      features,
      isMeAdmin,
      isMeSuperAdmin,
      ui,
    }
  },
  data() {
    return {
      isRepliesVisible: false,
      hover: false,
      edit: false,
      like: false,
    }
  },
  computed: {
    menuItems() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      const { comment } = this
      const isSourceOwner = get(this.sources, 'source.isMeOwner', false)
      const isAdmin = this.isMeAdmin || this.isMeSuperAdmin

      return [
        // {
        //   icon: 'rec/alert',
        //   content: 'Comment Reported',
        //   hidden: comment.isReportedByMe,
        //   disabled: true,
        // },
        {
          icon: 'rec/alert',
          content: 'Report Comment',
          hidden: comment.isMeAuthor, // !comment.isReportedByMe || comment.isMeAuthor
          click: () => {
            modal.show('rec-comment-report', {
              props: { comment },
            })
          },
        },
        {
          icon: 'rec/edit',
          content: 'Edit Comment',
          hidden: !isAdmin && !comment.isMeAuthor,
          click: () => {
            this.edit = true
          },
        },
        {
          icon: 'rec/bin',
          content: 'Delete Comment',
          hidden: !isSourceOwner && !isAdmin && !comment.isMeAuthor,
          click: () => {
            modal.show('rec-comment-delete', {
              props: { comment },
              on: {
                deleted: async () => {
                  this.processor.removeHit(comment)
                  await this.processor.refresh()
                  this.post.updateTotalComments()
                },
              },
            })
          },
        },
      ]
    },
    isDesktop() {
      return ui.desktop
    },
  },
  methods: {
    onMouseOver() {
      this.hover = true
    },
    onMouseLeave() {
      if (this.$refs.menu) this.$refs.menu.hide()
      nextTick(() => {
        this.hover = false
      })
    },
    hideEditInput() {
      this.edit = false
      this.hover = false
    },
    onLike() {
      if (!this.comment.isLikedByMe) {
        return this.comment.like()
      }
    },
    onRepliesClick() {
      if (this.comment.totalComments) {
        this.isRepliesVisible = true
      }
    },
    onReplyClick() {
      this.isRepliesVisible = true

      this.$emit('reply', {
        entity: this.reply
          ? this.processor.entity
          : this.comment.comments.entity,
        comment: this.comment,
      })
    },
    processText(text) {
      return processTextForUrls(fromIdToMention(text, this.comment.mentions))
    },
    openProfile(id) {
      if (this.features.directory) {
        this.$router.replace({ query: { recProfileId: id } })
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="flex pl-2.5">
      <comment-input
        v-if="edit"
        :comment="comment"
        class="order-1 mr-[30px]"
        @success="edit = false"
        @keydown.esc="edit = false"
      />

      <div
        v-else
        class="relative z-30 order-1 flex flex-1 transition duration-100"
        :class="{
          'z-30 transform hover:-translate-x-2.5': hover && isDesktop,
        }"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
      >
        <div
          class="relative mr-[30px] w-full rounded-3xl bg-dark px-[15px] py-2.5 text-sm leading-4 text-eonx-neutral-600"
        >
          <b
            class="text-black"
            :class="{
              'cursor-pointer': features.directory,
            }"
            @click="openProfile(comment.author.id)"
          >
            {{ comment.isMeAuthor ? 'You' : comment.author.fullName }} -
          </b>
          <span
            class="whitespace-pre-wrap"
            style="word-break: break-word"
            v-html="processText(comment.content)"
          />

          <media-gallery
            v-if="comment.attachments.hasPreviewAttachments"
            type="comment"
            :attachments="comment.attachments"
          />
          <media-audio
            v-if="comment.attachments.hasAudioAttachments"
            type="comment"
            :attachments="comment.attachments"
          />
          <media-downloadable
            v-if="comment.attachments.hasDownloadableAttachments"
            type="comment"
            :attachments="comment.attachments"
          />

          <div
            v-if="comment.totalLikes"
            class="absolute bottom-0 right-0 mb-2.5 mr-2.5 flex translate-y-full transform items-center justify-center rounded-full bg-white px-2.5 py-[5px] text-eonx-neutral-600 shadow-sm"
          >
            <base-icon
              svg="rec/heart-filled"
              size="xs"
              class="text-eonx-neutral-600"
            />
            <b class="ml-[5px] cursor-default text-xs leading-3">
              {{ comment.totalLikes }}
            </b>
          </div>
        </div>
        <transition
          enter-class="translate-x-[30px] opacity-0"
          leave-to-class="translate-x-[30px] opacity-0"
        >
          <div
            v-if="hover || !isDesktop"
            class="absolute right-0 top-0 mt-1.5 -translate-x-0.5 transform transition duration-200 md:translate-x-0.5"
          >
            <base-menu ref="menu" :items="menuItems" position="right">
              <template #trigger>
                <base-button
                  icon="plain/more"
                  class="flex self-center text-primary"
                />
              </template>
            </base-menu>
          </div>
        </transition>
      </div>

      <div
        class="flex items-center justify-center"
        :class="{
          'cursor-pointer': features.directory,
        }"
        @click="openProfile(comment.author.id)"
      >
        <base-avatar
          :src="comment.author.avatarUrl"
          :abbr="comment.author.initials"
          :size="reply ? 'xxs' : 'sm'"
          class="order-none mr-2.5"
        />
      </div>
    </div>

    <div class="mt-[5px] pl-5 md:pl-[30px]">
      <div
        :class="{
          'pl-10': reply,
          'pl-14': !reply,
        }"
      >
        <div v-if="!edit" class="flex items-end">
          <b
            v-if="comment.isLikedByMe"
            class="cursor-default text-sm text-eonx-neutral-600"
          >
            Liked
          </b>
          <base-button v-else look="link" size="md" @click="onLike">
            Like
          </base-button>
          <base-button
            v-if="showReply"
            look="link"
            size="md"
            class="ml-[15px]"
            @click="onReplyClick"
          >
            Reply
          </base-button>
          <base-date
            :date="comment.createdAt"
            type="from-now"
            class="ml-[15px] text-xs font-bold text-eonx-neutral-600"
          />
        </div>

        <base-button
          v-if="comment.totalComments && !isRepliesVisible"
          look="link"
          size="md"
          class="mt-2.5"
          @click="onRepliesClick"
        >
          <div class="flex items-center">
            <base-icon svg="rec/reply" size="sm" class="mr-[5px]" />
            {{ comment.totalComments }} Replies
          </div>
        </base-button>
      </div>

      <slot v-if="isRepliesVisible" />
    </div>
  </div>
</template>
